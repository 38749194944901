import { BaseModel } from 'modules/core/models/base-model';

export class BulletinRevisionField extends BaseModel {
    id                = null;
    code              = null;
    order             = null;
    bulletin_field_id = null;
    sheet_number      = null;
    line              = null;
    column            = null;
    decimal_places    = null;
    reference_field   = null;
}
