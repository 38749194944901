import { BaseModel } from 'modules/core/models/base-model';

const OE_ELEMENTS     = 1,
      OE_AXIS         = 2,
      OE_BRANCHES     = 3,
      OE_EDGES        = 4,
      OA_TYPES        = 5,
      OA_ELEMENTS     = 6,
      OA_SUB_ELEMENTS = 7,
      OH_TYPES        = 8,
      OH_ELEMENTS     = 9,
      OH_SUB_ELEMENTS = 10;

export class ConstructionType extends BaseModel {

    id          = null;
    slug        = null;
    needsParent = false;

    constructor(slug) {
        super();
        this.slug        = slug;
        this.id          = ConstructionType.slugToId(slug);
        this.needsParent = (this.id === OA_SUB_ELEMENTS || this.id === OH_SUB_ELEMENTS);
    }

    static get OE_ELEMENTS() {
        return OE_ELEMENTS;
    }

    static get OE_AXIS() {
        return OE_AXIS;
    }

    static get OE_BRANCHES() {
        return OE_BRANCHES;
    }

    static get OE_EDGES() {
        return OE_EDGES;
    }

    static get OA_TYPES() {
        return OA_TYPES;
    }

    static get OA_ELEMENTS() {
        return OA_ELEMENTS;
    }

    static get OA_SUB_ELEMENTS() {
        return OA_SUB_ELEMENTS;
    }

    static get OH_TYPES() {
        return OH_TYPES;
    }

    static get OH_ELEMENTS() {
        return OH_ELEMENTS;
    }

    static get OH_SUB_ELEMENTS() {
        return OH_SUB_ELEMENTS;
    }

    static slugToId(slug) {
        switch (slug) {
            case 'oe-elements':
                return OE_ELEMENTS;
            case 'oe-axis':
                return OE_AXIS;
            case 'oe-branches':
                return OE_BRANCHES;
            case 'oe-edges':
                return OE_EDGES;
            case 'oa-types':
                return OA_TYPES;
            case 'oa-elements':
                return OA_ELEMENTS;
            case 'oa-sub-elements':
                return OA_SUB_ELEMENTS;
            case 'oh-types':
                return OH_TYPES;
            case 'oh-elements':
                return OH_ELEMENTS;
            case 'oh-sub-elements':
                return OH_SUB_ELEMENTS;
        }
    }
}
