import { BaseModel } from 'modules/core/models/base-model';

export class Bulletin extends BaseModel {
    id                   = null;
    name                 = null;
    norm                 = null;
    test_code            = null;
    observations         = null;
    last_revision_number = null;
}
