import { BaseModel } from 'modules/core/models/base-model';

const PROVENIENCE     = 1,
      NATURE          = 2,
      PERCENTAGE      = 3,
      DENSITY         = 4,
      SUPPLIER        = 5,
      UNITS           = 6,
      VOLUMETRIC_MASS = 7;

const PROVENIENCE_FIELD_NAME     = 'provenance',
      NATURE_FIELD_NAME          = 'nature',
      PERCENTAGE_FIELD_NAME      = 'percentage',
      DENSITY_FIELD_NAME         = 'density',
      SUPPLIER_FIELD_NAME        = 'supplier',
      UNITS_FIELD_NAME           = 'units',
      VOLUMETRIC_MASS_FIELD_NAME = 'volumetric_mass';

export class CompositionField extends BaseModel {

    id   = null;
    name = null;

    static get PROVENIENCE() {
        return PROVENIENCE;
    }

    static get NATURE() {
        return NATURE;
    }

    static get PERCENTAGE() {
        return PERCENTAGE;
    }

    static get DENSITY() {
        return DENSITY;
    }

    static get SUPPLIER() {
        return SUPPLIER;
    }

    static get UNITS() {
        return UNITS;
    }

    static get VOLUMETRIC_MASS() {
        return VOLUMETRIC_MASS;
    }

    static get PROVENIENCE_FIELD_NAME() {
        return PROVENIENCE_FIELD_NAME;
    }

    static get NATURE_FIELD_NAME() {
        return NATURE_FIELD_NAME;
    }

    static get PERCENTAGE_FIELD_NAME() {
        return PERCENTAGE_FIELD_NAME;
    }

    static get DENSITY_FIELD_NAME() {
        return DENSITY_FIELD_NAME;
    }

    static get SUPPLIER_FIELD_NAME() {
        return SUPPLIER_FIELD_NAME;
    }

    static get UNITS_FIELD_NAME() {
        return UNITS_FIELD_NAME;
    }

    static get VOLUMETRIC_MASS_FIELD_NAME() {
        return VOLUMETRIC_MASS_FIELD_NAME;
    }

    static getAllowedInMixtures() {
        return [PROVENIENCE, NATURE, PERCENTAGE, DENSITY, SUPPLIER, VOLUMETRIC_MASS];
    }

    static getFormFieldName(fieldId) {
        switch (fieldId) {
            case PROVENIENCE:
                return PROVENIENCE_FIELD_NAME;
            case NATURE:
                return NATURE_FIELD_NAME;
            case PERCENTAGE:
                return PERCENTAGE_FIELD_NAME;
            case DENSITY:
                return DENSITY_FIELD_NAME;
            case SUPPLIER:
                return SUPPLIER_FIELD_NAME;
            case UNITS:
                return UNITS_FIELD_NAME;
            case VOLUMETRIC_MASS:
                return VOLUMETRIC_MASS_FIELD_NAME;
            default:
                return null;
        }
    }

    static getFormFieldType(fieldId) {
        switch (fieldId) {
            case PROVENIENCE:
                return 'text';
            case NATURE:
                return 'text';
            case PERCENTAGE:
                return 'number';
            case DENSITY:
                return 'text';
            case SUPPLIER:
                return 'text';
            case UNITS:
                return 'text';
            case VOLUMETRIC_MASS:
                return 'text';
            default:
                return 'text';
        }
    }
}
