import { BaseModel } from 'modules/core/models/base-model';

export class BulletinRevision extends BaseModel {
    id                          = null;
    bulletin_id                 = null;
    revision_number             = null;
    bulletin_model              = null;
    effective_date              = null;
    norm_edition                = null;
    tests_quantity              = null;
    model_file                  = null;
    test_registered_in_platform = null;
}
